<template>
  <div>
    <Header />

    <section class="container-fluid">
        <section class="logo-group">
          <div class="logo-container">
              <img class="logo" src="@/assets/PachiExchangeLogo1.png" />
          </div>
              <p>Your Pachi+ Rewards Hub</p>
        </section>

        <section>
        <img
          src="@/assets/homebanner.png"
          alt="Pachi Exchange Banner"
          class="banner desktop-banner"
        />
        <img
          src="@/assets/homebanner-mobile.png"
          alt="Pachi Exchange Banner"
          class="banner mobile-banner"
        />
        </section>

        <Carousel />

      <section class="pachi-rewards">
        <div class="header">
            <h2 class="header-text">Three Easy Steps in Receiving Rewards</h2>
       </div>
        <div class="exchange-container">
            <div class="left-content">
                <div class="flex-item step-img-container">
                    <img src="@/assets/Group1.png"/>
                </div>
                <img class="flex-item" src="@/assets/pachi+_app_icon_study4_v1 1.png" />
                <div class="flex-item">
                    <h4 class="content-header">
                        Play Pachi+ with Pachi+ Account
                    </h4>
                    <p class="content-text">
                        Download Pachi+ on your device. Sign up for a Pachi+ Account before playing.
                    </p>
                </div>
           </div>
            <div class="arrow-container">
                <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
            </div>
            <div class="center-content">
                <div class="flex-item step-img-container">
                    <img src="@/assets/Group2.png" />
                </div>
                <img class="flex-item" src="@/assets/pachiticket.png" />
                <div class="flex-item">
                    <h4 class="content-header">
                        Win Pachi Tickets
                    </h4>
                    <p class="content-text">
                        Win Pachi Tickets as you play. Pachi Tickets are automatically credited to your Pachi+ Account.
                    </p>
                </div>
            </div>
            <div class="arrow-container">
                <img class="arrow-desktop" src="@/assets/arrow-white.png" alt="Arrow" />
                <img class="arrow-mobile" src="@/assets/arrow-down-white.png" alt="Arrow" />
            </div>
 
            <div class="right-content">
                <div class="flex-item step-img-container">
                    <img src="@/assets/Group3.png" />
                </div>
                <img class="flex-item" src="@/assets/gift.png" />
                <div class="flex-item">
                    <h4 class="content-header">
                        Use Pachi+ Tickets on Pachi Exchange
                    </h4>
                    <p class="content-text">
                        Claim your prizes and sweepstake entries on Pachi Exchange using Pachi Tickets.
                    </p>
                </div>
           </div>
        </div>
     </section>

      <section class="contain-fluid exchange-rewards">
          <div class="header">
              <h2 class="header-text">
                  Pachi Exchange Rewards
              </h2>
          </div>
          <div class="rewards">
            <div class="prizes">
                <a href="/shop">
                    <div class="img-container">
                        <img src="@/assets/patchicat.png"/>
                    </div>
                    <h3>Prizes</h3>
                </a>
            </div>
            <div class="sweepstakes">
                <a  href="/sweepstakes" >
                    <div class="img-container">
                        <img src="@/assets/coupon1.png" />
                    </div>
                    <h3 class="sweepstakes">Sweepstakes</h3>
                </a>
            </div>
          </div>
      </section>

      <section class="blog">
          <div class="header">
                <h2 class="header-text">Pachi Exchange Blog</h2>
                <h4 class="header-content">Welcome to our new community of Pachi Players</h4>
          </div>
        <div class="container">
            <p class="blog-content">
                You may have been wondering when it may arrive, "What?" I hear you ask well the Pachi Exchange of course.
                The entire Pachi team have been extremely hard at work creating an awesome rewards hub with all sorts of Pachi+ Perks to reward you for playing.
                This is of course linked to our beautiful curated Pachi+ Digital Pachinko game which has been out in the wild for a couple of months, the entire Pachi+ team has been very blessed 😇 as Pachi+ I'm pleased to say has been amassing a large amount of Pachi Pals and Super Fans across the globe 🤗.
                A big shout out to all of you, we thank you from the bottom of our hearts.
            </p>
            <a role="button" class="btn" href="https://pachiblast.pachiplus.com/pachiblast/yassss-it-is-finally-on-the-way">Read More</a>
        </div>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Carousel from '../components/Carousel.vue'
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    Header,
    Carousel,
    Footer,
  }
};
</script>

<style lang="scss" scoped>
// @import "@/styles/scss/newmain.scss";
.btn {
    font-weight: 400;
    font-size: 16px;
    color: #063B58;
    background-color: #fff;
    border-radius: 11px;
    text-align: center;
    width: 204px;
    height: 64px;
    padding-top: 19px;
}

.container-fluid {
    padding: 0;

    .banner {
        width: 100%;
    }

    .desktop-banner {
        @media (max-width: 576px) {
            display: none;
        }
    }

    .mobile-banner {
        @media (min-width: 576px) {
            display: none;
        }

    }
}

.logo-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    max-width: 75%;

    .logo-container {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
    
    p {
        font-size: 24px;
        font-weight: bold;
    }
    @media (min-width: 576px) {
        display: none;
    }

    @media (min-width: 769px) {
        top: 220px;
    }
}

.pachi-rewards {
    padding: 32px 24px;
    background-color: #063B58; 
    color: #fff;
    .header {
        line-height: 52px;
        margin: auto;
        font-weight: 700;

        .header-text {
            font-size: 1.5rem;

            @media (min-width: 576px) {
                font-size: 3rem;
            }
        }

        

    }
    .exchange-container {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width: 576px) {
            flex-direction: row;
            height: 500px;
        }

        .left-content, .center-content, .right-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .flex-item {
                padding-top: 12px;
                padding-bottom: 12px;

                &:first-child {
                    padding-top: 0;
                }

                .content-header {
                    font-weight: 700;
                }

                .content-text {
                    font-weight: 400;
                    font-size: 18px;
                }
            }

            .step-img-container {
                max-width: 90px;
            }
            
            img {
                max-width: 150px;
                width: 100%;
                object-fit: contain;
            }
            
        }

        .left-content {
            
            @media (min-width: 576px) {
                margin-right: 32px;
            }
        }

        .right-content {
            img {
                max-width: 144px;
                width: 100%;
                object-fit: contain;
            }
            @media (min-width: 576px) {
                margin-left: 64px;
            }
        }

        .arrow-container {
            .arrow-desktop {
                align-self: center;
                width: 100%;
                object-fit: contain;
                max-width: 220px;
                display: none;
                @media (min-width: 576px) {
                    display: block;
                }
            }
            .arrow-mobile {
                display: block;
                width: 100%;
                object-fit: contain;
                max-width: 80px;
                margin-bottom: 32px;
                @media (min-width: 576px) {
                    display: none;
                }
            }
    
        }
    }

    .exchange-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1150px;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 32px;
        @media (min-width: 576px) {
            flex-direction: row;
            .left-content {
                margin-right: 32px;
            }
            .right-content {
                margin-left: 32px;
            }
        }
    }
}

.exchange-rewards {
    padding: 32px 24px;
    color: #063B58;
    .header-text {
        font-weight: 700;
        font-size: 1.5rem;

        @media (min-width: 576px) {
            font-size: 3rem;
        }
    }

    .rewards {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        padding: 0 12px;

        a {
            color: #063B58;
        }
        a:hover {
            color: #063B58;
        }


        & > * {
                margin: 12px 0;

                h3 {
                    font-weight: 700;

                    @media (min-width: 576px) {
                        padding-top: 24px;
                    }

                }

           }

        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: space-evenly;
        }

        .img-container {
            max-width: 256px;

            @media (min-width: 769px) {
                max-width: 364px;
            }

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.blog {
    background-color: #063B58;
    color: #fff;
    padding: 0 16px;
    padding-bottom: 32px;

    .header {
        padding: 32px 0;
        .header-text {
            font-weight: 700;
            font-size: 2rem;
            padding-bottom: 28px;

            @media (min-width: 576px) {
                font-size: 3rem;
            }
        }

        .header-content {
            font-weight: 700;
            font-size: 1rem;

            @media (min-width: 576px) {
                font-size: 2rem;
            }
        }
        .btn{ 
            background-color: #fff;
            color: #063B58;
        }
   }

    .platform-imgs {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 1150px;
        width: 100%;
        align-items: flex-end;

        .platform {
           margin: 8px auto;

            a {
                color: white;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    color: white;
                }
            }

            p {
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin-top: 8px;
            }
        }

        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
}
.signup-button a{
    background-color: #063B58;
    color: white;
}
</style>