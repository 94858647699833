<template>
  <div>
    <Header />
    <Stepper :stepperItems="['Confirm Amount', 'Payment Option', 'Confirm Cashout']" :step="1" />
    <section>
        <div class="container shop-exchange">
            <div class="left-content">
                <div class="image-wrapper">
                    <img src="@/assets/cash_bundle.png" />
                </div>
            </div>
            <div class="right-content">
                <div class="header">
                    <h3 class="header-text">Input your cashout amount</h3>
                </div>
                <p>
                    Type in the amount you would like to cash out.
                </p>
               <p style="color: #034B73;">
                    *This process is irreversible.
                    Please double-check and review the amount before confirming this transaction.
                    Minimum cash out is $100, Maximum cash out is $999.
                    Fees and charges may apply, please read <a  style="color: #034B73;text-decoration: underline;" href="http://knowledgebase.pachiplus.com/what-are-the-terms-conditions-of-use" target="_blank">Terms and Conditions</a>.
                </p>
                <form class="form" action="/cashout/payment">
                    <div class="form-group details">
                        <div>
                            <label class="form-label">Available amount:</label>
                            <label class="form-label">{{ cashout }}</label>
                        </div>
                        <div>
                            <label class="form-label" for="tickets">Cashout amount:</label>
                            <input type="text" name="ticketAmount" id="ticketAmount" v-model="ticketAmount"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            class="btn"
                            @click.prevent="validateCashOut"
                            type="submit"
                            value="CONTINUE"
                        />
                    </div>
                </form>
                <p style="font-size: 1.2rem;">
                    Are You Verified?
                </p>
                <p>
                    To enable cashout requests on this website, Pachi Exchange
                    requires proper user identification. Please make sure your
                    account profile is complete.
                </p>
            </div>
        </div>
        <b-modal v-model="showModal" centered hide-footer hide-header-close>
            <template #modal-title>
                <img src="@/assets/logo-white.png" />
            </template>
            <div class="d-block text-center">
                <p>{{error}}</p>
                <p>Please see <a href="http://knowledgebase.pachiplus.com/what-are-the-terms-conditions-of-use">terms and conditions</a>.</p>
                <button class="btn" @click.prevent="showModal = !showModal">
                    Back
                </button>
            </div>
        </b-modal>
   </section>
    <Footer />
  </div>
</template>

<script>
// import { userService } from '../services';
import { apiurl } from "../server.config";
const token = localStorage.getItem("token");
import { mapState } from "vuex";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stepper from "../components/Stepper.vue"
import { router } from "../router";
export default {
  name: "CashoutAmount",
  components: {
    Header,
    Footer,
    Stepper
  },
  data() {
    return {
      showModal: false,
      tickets: 0,
      cashout: 0,
      ticketAmount: 0,
      error: '' 
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
    }),
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      const id = this.account.user.data.id;
      axios
        .get(apiurl + "exchange/myuserdetails/" + id, { headers: headers })
        .then((response) => {
          this.cashout =
            response.data.cashoutTotal == null ? 0 : response.data.cashoutTotal;
        });
    },

    validateCashOut() {
      if (this.cashout >= this.ticketAmount && this.ticketAmount >= 100 && this.ticketAmount <= 999) {
          router.push({
              name: 'Payment Option',
              params: {
                  cashoutAmount: this.ticketAmount
              }
          })
      } else {
        this.showModal = true;
        switch (true) {
            case this.cashout < this.ticketAmount:
                this.error = "Cashout amount should not be more than available amount."
                break;
            case this.ticketAmount < 100:
                this.error = "Oops. You don't have enough cash to cash out. The minimum cash out is $100."
                break;
            case this.ticketAmount > 999:
                this.error = "Oops. You have exceeded the maximum cash out value of $999."
                break;
            default:
                this.error = "Error in processing your cashout."
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-exchange {
        padding: 32px 0;
        display: flex;
        flex-direction: row;

        .left-content {
            display: none;

            @media (min-width: 576px) {
                display: block;
                margin-right: 40px;
                padding: 24px;

                .image-wrapper {
                    width: 230px;
                    height: 230px;
                    background-color: #063B58;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center
                }

           }
        }

        .right-content {
            text-align: center;
            padding: 12px;

            &> * {
               margin: 18px 0; 
            }

            p {
                text-align: center;
                font-weight: 600;
                font-size: 1rem;

                @media (min-width: 576px) {
                    text-align: left;
                }
            }

            @media(min-width: 576px) {
                margin-left: 40px;
                padding: 0;
                text-align: left
            }
        }

        .header-text {
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: capitalize;
            @media(min-width: 576px) {
                font-size: 3rem;
            }
 
        }

        .header-text {
            color: #063B58;
        }
    }

    .form {
        padding-top: 12px;
        justify-content: space-between;
        font-weight: 700;
        display: flex;

        .details {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 0;
            flex-direction: column;

            @media(min-width: 756px) {
                justify-content: flex-start;
            }
        }

        @media(max-width: 576px) {
            flex-direction: column;
            align-items: center;
        }
        
        label {
            margin-right: 12px;
        }
    }
    #ticketAmount {
        border: 3px solid #063B58;
    border-radius: 8px;
    height: 48px;
    width: 148px;
    text-align: center;

    }
    .btn {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        background-color: #063B58;
        border-radius: 11px;
        text-align: center;
        text-transform: uppercase;
        padding: 12px 24px;
        margin-top: 12px;

        &:hover {
            color: #ffffff;
            outline: none;
        }
    }

    ::v-deep .c-stepper__item {
        &:last-child {
            h3 {
                margin-top: 2px;
            }
        }
    }

::v-deep .modal-header {
    border: none;
    background-color: #063B58;
}

::v-deep .modal-body {
    background: url("../assets/sweepstakes-modal-background.png");
    background-position: center;
    background-size: cover;
    background-repeat:  no-repeat;
    color: #063B58;
    font-size: 1.2rem;
    font-weight: bold;

    a {
        color: #063B58;
        text-decoration: underline;
    }

}
 
</style>
